.sortable-header {
    transition: color 0.3s;
    align-items: center;
  }
  
  .sortable-header:hover {
    color: #811113 ; 
  }
  
  .sortable-header > svg {
    align-self: flex-end;
    vertical-align: middle;
  }