.tabs {
    display: flex;
    justify-content: space-between;
    width: 30%;
    
}

.tabs button {
    background-color: lightgray;
    border: none;
    color: #888888;
    cursor: pointer;
    padding: 15px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.5s ease-out;
    border-radius: 2px;
}

.tabs button:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.15);
}

.tabs button:disabled {
    background: linear-gradient(245.39deg, #ece3e3 15.14%, #cf5a5a 83.06%);
    color: white;
}

