// 
// authentication.scss
//
.body{
    height: 100vw;
}

// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// auth 2

.auth-logo{
    .auth-logo-dark{
        display: $display-block;
    }
    .auth-logo-light{
        display: $display-none;
    }
}

.auth-body-bg{
    background-color: $card-bg;
}


// auth-pass-inputgroup

.auth-pass-inputgroup{
    input[type="input"] + .btn .mdi-eye-outline{
        &:before{
            content: "\F06D1";
        }
    }
}

// authentication full page

.auth-full-bg{
    background-color: rgba($primary, 0.25);
    display: flex;

    @media (min-width: 1200px){
        height: 100vh;
    }
    

    &::before{
        content: "";
        position: absolute;
        width: 300px;
        height: 300px;
        border-radius: 50%;
    }

    .bg-overlay{
        background: url("../../../images/bg-auth-overlay.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.auth-full-page-content{
    display: flex;

    @media (min-width: 1200px){
        min-height: 100vh;
    }
}

.auth-review-carousel{
    &.owl-theme {
        .owl-dots {
            .owl-dot{
                span{
                    background-color: rgba($primary, 0.25);
                }
                &.active, &:hover{
                    span{
                        background-color: $primary;
                    }
                }
            }
        }
    }
}

.text-login{
    color:#7E0B0E;
}
.text-login-cont{
    color:#7E0B0E;
    text-align: center;
    font-size: large;
    height: 100px;
    display: flex;
    background-color: #fff;
    // background: linear-gradient(0deg, rgba(255, 255, 255, 0.862), #717070c3),url("../../../images/gtc-office.jpg");
    background-size: cover;
    background-position: center 40%;
    background-repeat: no-repeat;
}
.text-margin{
    margin:auto;
}
.text-white{
    // height: 70px;
    // width: 270px;
    border-width: 2px;
    // border-color: linear-gradient(0deg, rgba(255, 255, 255, 0.676), #ffffff00);
    // background: linear-gradient(0deg, rgba(255, 255, 255, 0.676), #ffffff);
    // box-shadow: 0 0 10px 10px rgb(255, 255, 255);
    // background: radial-gradient(
    //     rgba(255, 255, 255, 0.896),
    //     rgba(255, 255, 255, 0)), #ffffff00;
    border-radius: 10px;
}

.btn-login{
    color: #fff;
    background-color: #7E0B0E;
    border-color: #7E0B0E;
    border-radius: 20px;
    width: 380px;

}
.btn-login:hover{
    color: #fff;
    background-color: #c1474b;
    border-color: #7E0B0E;
}

.bg-login.bg-soft {
    background-color: #f7e2e3;
}
.login-img{
    background-image: url("../../../images/log-in.jpeg");
    background-size: cover;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    // height: fit-content;
    // background-size: auto;
    // background-repeat:   no-repeat;
    // background-position: center center;
    // width: 100%;
    // height: 70vw;
    // background-attachment: fixed;
    // position: relative;
    // padding-top: 10px;
}
.login-body{
    padding-top: 30px;
}
.login-card-body{
    width: 450px;
    height: 310px;
}
.authCard{
    border-radius: 10px;
    padding-top: 8%;
    padding-bottom: 10%;
    // height: 60%;
    // width: 35%;
    // min-width: 585px;
}

@media screen and (max-width: 1300px) {
    .login-img{
        background-size: cover;
        background-repeat: repeat-y;
    }
  }
.login-image-container{
 align-items: left;
 padding:45px 0 0 60px;
}
.login-logo{
    width: 300px;
    height: 100px;
}
.loginCard{
    margin-left:auto;
    margin-right:auto;
    width:450px;
    --bs-card-border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.login-input{
    border-radius: 20px;
}
