.btn-custom{
    background-color:  #821113;
    margin-left: 10px;
    width: 150px;
    height: 40px;
    font-size: 14px;
  }

.btn-custom1{
  background-color:  #821113;
}

.btn-custom2{
border-color: #821113 !important;
color: #821113  !important;
}

.table{
  --bs-table-bg:#fff !important
}
.scrollable{
  overflow-y: auto;
  max-height: 100vh;
}

.scrollable1{
  overflow-y: auto;
  max-height: 28vh;
}

.report_table_edit{
  overflow-y: auto;
  max-height: 500px;
}
.report_table_edit thead{
  position: sticky;
  top: 0;
  background-color: white;

}
.report_table_edit :hover{
  cursor: pointer;
}


.nav-pills .nav-link.active{
  color:white;
  background-color: #8e292b;
}
.nav-pills{
  
  height: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.tab-style{
  // margin-left: 1.2%;
  // margin-right: 1.2%;
}

.tab-style :hover{  
  color : #8e292b;
}

.kpichart-style{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; 
}
.custom-context-menu {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 8px;
  z-index : 100;
}
.custom-context-menu .react-contextmenu-item {
  cursor: pointer;
  padding: 4px 8px;

}
.custom-context-menu .react-contextmenu-item :hover{
  background-color: #f0f0f0;
}
;
.custom-context-menu .react-contextmenu-item:hover {
  background-color: #f0f0f0;
}
.country_col .react-contextmenu-wrapper{
  width : 200px !important;
}
.operator_col .react-contextmenu-wrapper{
  width : 270px !important;
}
.timestamp_col .react-contextmenu-wrapper{
  width : 200px !important;
}
.price_col .react-contextmenu-wrapper{
  width : 160px !important;
}
.sentid_col .react-contextmenu-wrapper{
  width : 120px !important;
}

.select-filter{
  width: 100% !important;
  height: 100% !important;
  border: none !important; 
  background-color: transparent !important;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: bold !important;
  color: #8e292b !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}
.text-filter{
  width: 100% !important;
  height: 100% !important;
  border: none !important; 
  background-color: transparent !important;
  text-align: center !important;
  font-size: 14px !important;
  font-weight: bold !important;
  color: #8e292b !important;
}

.reportdata_table12 .react-bootstrap-table {
  overflow-y: auto;
  max-height: 28vh !important;
}

.reportdata_table th{
  //center the text and align it to the top of the td
  vertical-align: top;
  background-color: rgba(129, 17, 19, 0.05);
  text-align: center;
  border: 1px solid #dee2e6;
  font-size: 14px;
  .form-control:focus {
    color: #8e292b !important;
    
  }
  
  .form-control{
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }

  .form-control:hover{
    transform: scale(1.2);
  }
}
// .reportdata_table table{
//   width: 3400px;
//   table-layout: fixed; 
//   text-align: center;
// }

// .reportdata_table12 table{
//   width: 2600px !important;
//   table-layout: fixed; 
//   text-align: center;
// }

.reportdata_table table{
  text-align: center;
}

.reportdata_table .react-contextmenu-wrapper{
  height: 100%;
  // width: 140px;
  // min-width: 120px;
  text-align: center;
}



.reportdata_table2 th{
  //center the text and align it to the top of the td
  vertical-align: top;
  background-color: rgba(129, 17, 19, 0.05);
  text-align: center;
  border: 1px solid #dee2e6;
  font-size: 14px;
  .form-control:focus {
    color: #8e292b !important;
    
  }
  
  .form-control{
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }

  .form-control:hover{
    transform: scale(1.2);
  }
}
.reportdata_table2 table{
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  text-align: center;
}
.reportdata_table2 .react-contextmenu-wrapper{
  height: 100%;
  // width: 140px;
  // min-width: 120px;
  text-align: center;
}

.rs-input-group.rs-input-group-inside{
  height: 38px !important;
  width: auto !important;
  background-color: hsl(0, 0%, 100%) !important;
  border-color: hsl(0, 0%, 80%) !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  cursor: default;
}

.rs-input {
  font-size: 13px !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
  background-color: rgb(129, 17, 19) !important;
}

.rs-calendar-table-cell-in-range::before{
  background-color: rgb(129, 17, 19, 0.1) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content{
  box-shadow: inset 0 0 0 1px rgb(129, 17, 19) !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover{
  background-color: rgb(129, 17, 19, 0.5) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content:hover{
  background-color: rgb(129, 17, 19, 0.5) !important;
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content{
  background-color:rgb(129, 17, 19, 0.5) !important;
  color: #fff;
}

.rs-btn-link{
  color: rgb(129, 17, 19) !important;
}

.rs-btn-primary:disabled, .rs-btn-primary.rs-btn-disabled {
  background-color:rgb(129, 17, 19, 0.5) !important; ;
}

.rs-btn-primary{
  background-color:rgb(129, 17, 19) !important
}

.test_date{
  width: 115px;
}

// th.country-header2{
//   width: 700px;
// }
// th.country-header3{
//   width: 250px;
// }
// th.smsc_owner_header{
//   width: 120px;
// }


th.id-header{
  min-width: 100px;
}

th.country-header{
  min-width: 100px;
}

th.operator-header{
  min-width: 150px;
}

th.is-delivered-header {
  min-width: 100px; 
}

th.timestamp-header{
  min-width: 160px;
}

th.delivery-header{
  min-width: 100px;
}

th.b_number-header{
  min-width: 110px;
}

th.sender_id_type-header{
  min-width: 100px;
}

th.price-header{
  min-width: 100px;
}

th.sent_sender_id-header{
  min-width: 100px;
}

th.received_sender_id-header{
  min-width: 100px;
}

th.is_sender_id_preserved-header{
  min-width: 100px;
}

th.smsc-header{
  min-width: 150px;
}

th.expected_sms-header{
  min-width: 100px;
}

th.smsc_owner-header{
  min-width: 100px;
}

th.sent_text-header{
  min-width: 150px;
}

th.received_text-header{
  min-width: 150px;
}

th.is_text_preserved-header{
  min-width: 100px;
}

th.gate-header{
  min-width: 150px;
}

// .react-bootstrap-table>table>thead>tr>th {
//   display: flex;
//   justify-content: center; /* Center children horizontally */
//   align-items: center; /* Center children vertically */
// }
// _::-webkit-full-page-media, _:future, :root .safari_only {
//   .react-bootstrap-table>table>thead>tr>th .filter-label{
//       margin-left: 26%;
//    }
// }
.tableFilter{
  //center the label component thats inside the div so that the text is in the middle
  display: flex;
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
}
.pagination{
  li>a{
    border : none !important
  }
}

.disabled-action {
  opacity: 0.5; /* You can adjust the opacity to visually indicate it's disabled */
  pointer-events: none; /* This prevents mouse events from being applied */
  cursor: not-allowed; /* Optional: Change the cursor to indicate it's not clickable */
}
.analytics_results_table{
  .react-bootstrap-table {
    max-height: 2000px !important;
  }
}

.rotated-header-text-rotated {
  transform: rotate(-45deg);
}

.rotated-header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    // transform: rotate(-45deg);
    font-size: 10px;
    // width: 5ch; // Adjust as needed
    white-space: normal;
    // word-wrap: break-word;
}

.reportdata_table3 th{
  //center the text and align it to the top of the td
  vertical-align: top;
  background-color: rgba(129, 17, 19, 0.05);
  text-align: center;
  border: 1px solid #dee2e6;
  font-size: 14px;
  .form-control:focus {
    color: #8e292b !important;
    
  }
  
  .form-control{
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }

  .form-control:hover{
    transform: scale(1.2);
  }
}
.reportdata_table3 table{
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  text-align: center;
}
.reportdata_table3 .react-contextmenu-wrapper{
  height: 100%;
  // width: 140px;
  // min-width: 120px;
  text-align: center;
}


.scrollable2{
  overflow-y: auto;
  max-height: 1300px !important;
  .react-bootstrap-table{
    max-height: 800px !important;
  }
}


/* Customer and Admin KPI table header minimum width setting*/

th.min-width-th-130{
  min-width: 130px;
}

th.min-width-th-120{
  min-width: 120px;
}

th.min-width-th-110{
  min-width: 110px;
}

th.min-width-th-100{
  min-width: 100px;
}

th.min-width-th-90{
  min-width: 90px;
}

th.min-width-th-80{
  min-width: 80px;
}

th.min-width-th-70{
  min-width: 70px;
}

th.min-width-th-60{
  min-width: 60px;
}

th.min-width-th-50{
  min-width: 50px;
}

th.min-width-th-40{
  min-width: 40px;
}