// 
// Page-title
// 

.page-title-box {
    padding-bottom: $grid-gutter-width;

    .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    h4 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px !important;
    }
}
.user-breadcrumb{
    .page-title-box {
        padding-bottom: 0;
        h4{
            font-size: 16px !important;
            font-weight: 600;
        }
    }
}
