.view-btn-tbl{
    color:#c24345;
    background-color:#ffffff;
    padding: 1px 1px 1px 1px;
    border:none;
    border-radius: 5px;
    //width: 10%;
}   
.view-btn-tbl:hover{
    color:#881D1F;
    background-color:#eff1f3;
    border:none;
}
.modal-popscreen{
    margin-top: 5%;
    width:50%;
    height:50%;
    
}
.lable-smsc{
    margin: 10px;
}
.input-smsc{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-right: 10px;
    border-radius: 1px;
    border:none;
}

.disabled {
    pointer-events: none; /* Disable pointer events */
    cursor: not-allowed; /* Change cursor to not allowed */
    opacity: 0.5;
  }