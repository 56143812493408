.title-operator{
    width: 100%;
}

// .input-dtl-leb2{
//     margin-right: 10px;
//     width: 100%;
//     overflow-wrap: normal;
// }

.output-leb2{
    color:rgb(43, 56, 231);
   
    
}

.input-dtl-leb3{
    margin-right: 10px;
    width: 70%;
}
.gts-container{
    margin-bottom: 40px;
}

.output-leb3{
    color:rgb(43, 56, 231);
    margin-left: 10px;
    
}

.input-operator{
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.42rem 0.70rem;
    margin-top: 6px;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.input-multiple{
    display: block;
    width: 95%;
    padding: 0.42rem 0.70rem;
    margin: 10px 10px 10px 10px;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.delete-btn-oper{
    background-color:#F89F9F;
    margin: 10px 10px 10px 10px;
    width: 95%;
}
.operator-label{
    width:100%;
    margin-top: 6px;
}

.fs-18{
    font-size: 18px;
    
}

.fs-14{
    font-size: 14px;
}

.fw-600{
    font-weight: 600;
}

.customer-field-container{
    width: 90%;
    margin-left: 2%;
}
.lab-col{
    width:5%;
}
.input-col{
    width: 25%;
}
.modal-operator-screen{
    width: 90%;
    min-width: 925px;
    max-width: none;
    .modal-body{
        //add padding to the sides and center the content
        .form-width{
            margin-right: auto;
            margin-left: auto;
        }

    }
}
.add-operator-modal {
    display: flex;
    justify-content: center;
    min-width: 925px;
    align-items: center;
    height: 100vh; /* Ensure the container takes the full height of the viewport */
  }

.add-smsc{
    background-color:#7E0B0E;
    margin-bottom: 10px;
}
.add-smsc:active{
    background-color:#7E0B0E;
}

.smsc-radio{
    padding-top: 15px;
}
.operator-phone-input{
    display: flex;
    width: 100%;
    padding: 0.42rem 0.70rem;
    margin-top: 10PX;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.css-b62m3t-container{
    padding-top: 5px;
}

.form-width{
    width: 800px;
}


.gatekeeper-card{
    border: 1px solid #E3E3E3;
    border-radius: 5px;
}

.PhoneInputInput{
   border: none;
}

.smsc-card{
    border:1px solid #E3E3E3 ;
    border-radius: 5px;
    height: 279px;
}


.addNew-hr{
    margin-top: 20px;
    width: 750px;
}
.operator-label_weight{
    width:100%;
    margin-top: 10px;
    font-weight: 500 !important;
}
.sub_h{
    font-size: 16px;
    font-weight: 600 !important;
}
.normal_h{
    font-size: 13px;
    font-weight: 400 !important;
}
.modal_footer_op{
    display: flex;
    justify-content: space-between;
    min-width: 820px;
    text-align: right;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}
.btn_cancel{
    background-color: #E8E8E9;
    color: black !important;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 10px;
}
.btn_cancel:hover{
    background-color: #d1d1d1;
}
.btn_save{
    background-color: #7E0B0E;
    color: white !important;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
}
.btn_save:hover{
    background-color: #b44f53;
}
.operator-mult-input{
    margin-top: 0.5rem !important;
    padding-top: 0px !important;
}