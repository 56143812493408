.fixed-height { /* Consistent height for all Select fields */
    white-space: nowrap; /* Prevent text from wrapping onto multiple lines */
/* Prevent overflowing content from creating extra space */
    text-overflow: ellipsis; /* Truncate text with ellipsis if too long */
  }
  
  .fixed-height .Select__placeholder {
    white-space: nowrap;

    text-overflow: ellipsis;
  }

.custom-cardbody{
    padding: 32px !important;
}