@media (max-width: 1507px) {
    .action {
      padding-left: 120px !important; // Adjust this value as needed
    }
    .action-data {
      padding-left: 120px !important; // Adjust this value as needed
    }
  }

@media (max-width: 1372px) {
    .action {
      padding-left: 100px !important; // Adjust this value as needed
    }
    .action-data {
      padding-left: 100px !important; // Adjust this value as needed
    }
  }

@media (max-width: 1337px) {
    .action {
      padding-left: 100px !important; // Adjust this value as needed
    }
    .action-data {
      padding-left: 100px !important; // Adjust this value as needed
    }
    .role {
        padding-left: 60px !important; // Adjust this value as needed
      }
  }

  @media (max-width: 1287px) {
    .action {
      padding-left: 60px !important; // Adjust this value as needed
    }
    .action-data {
      padding-left: 60px !important; // Adjust this value as needed
    }
    .role {
        padding-left: 40px !important; // Adjust this value as needed
      }
  }

  @media (max-width: 1209px) {
    .action {
      padding-left: 40px !important; // Adjust this value as needed
    }
    .action-data {
      padding-left: 40px !important; // Adjust this value as needed
    }
    .role {
        padding-left: 20px !important; // Adjust this value as needed
      }
  }

  @media (max-width: 1088px) {
    .action {
      padding-left: 20px !important; // Adjust this value as needed
    }
    .action-data {
      padding-left: 20px !important; // Adjust this value as needed
    }
    .role {
        padding-left: 20px !important; // Adjust this value as needed
      }
  }

  @media (max-width: 1047px) {
    .action {
      padding-left: 0px !important; // Adjust this value as needed
    }
    .action-data {
      padding-left: 0px !important; // Adjust this value as needed
    }
    .role {
        padding-left: 0px !important; // Adjust this value as needed
      }
  }
