// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
  }

  .table-light{
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}


.table-dark>:not(:last-child)>:last-child>*{
  border-bottom-color:  $table-dark-border-color;
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}

.table_shadow_div{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.react-bootstrap-table{
  overflow-y: auto;
  max-height: 60vh;
}
.react-bootstrap-table thead{
  position: sticky;
  z-index: 0;
  top : 0;
  background-color: white;
}
.react-bootstrap-table thead:before {
  content: "";
  position: absolute;
  top: -1;
  left: 0;
  right: 0;
  margin-top:-1px;
  border-top: 3px solid #eff2f7;
}

.pagination {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.pagination li {
  display: inline-block;
  margin-right: 5px;
}

.pagination li a {
  color: #8e292b;
  border: 1px solid #8e292b;
  padding: 5px 10px;
  border-radius: 5px;
}

.pagination li.active a {
  background-color: #8e292b;
  color: #fff;
  border-color: #8e292b;
  &:hover {
    background-color: #8e292b;
    color: #fff;
    border-color: #8e292b;
  }
}

// .new-dropzone {
//   min-height: 230px;
//   border: 2px dashed red !important;
//   background: $card-bg;
//   border-radius: 6px;
// }

// .new-dropzone .dz-message {
//   font-size: 24px;
//   width: 100%;
// }

// .new-dropzone .dz-message:focus {
//   outline: none;
// }

.dropzone1 {
  min-height: 150px;
  border: 2px dashed #BF9293;
  background: $card-bg;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 23px;

  .dz-message {
    font-size: 24px;
    width: 100%;
    &:focus{
      outline: none;
    }
  }
}


.dropzone2 {
  min-height: 277px;
  border: 3px dashed #BF9293;
  background: $card-bg;
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 23px;

  .dz-message {
    font-size: 24px;
    width: 100%;
    &:focus{
      outline: none;
    }
  }
}

.dropzone-row-2{
  display: flex;
  justify-content: flex-end;
}


.upload_icon{
  color: #BF9293 !important;
  width: 28px;
  height: 19px;
  justify-content: center;
  margin-top: 20px;


}

.upload_text{
  color:#811113 !important;
  ;
}



.support_text{
font-family: Poppins;
font-size: 13px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: #B7B8BB;

}

.file_text{
font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;
text-align: left;
color: #4E5157;


}

.select-text{
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}

.card_new{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  padding: 4px;
  width: 100%;
  height: 300px;
  
}

.card_new2{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  padding: 4px;
  width: 100%;
}

.card_new3{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  padding: 4px;
  width: 100%;
  height: 410px;
  
}



.box2{
  height: 25px;
  width: 25px;
  background-color: #F8F8F8;
  border-radius: 50%;
  display: inline-block;
  text-align: center;

}

.box3 {

width: 64px;
height: 64px;
top: 400px;
border-radius: 10px;
background-color: #F8F8F8;
display: inline-block;
}



.image{
width: 24.06px;
height: 28.44px;
top: 983.78px;
display: block;
margin: 0 auto;
}

.trash_icon{
  color: #F47673 !important;
  width: 32px;
  height: 35px;
  top: 4.25px;
  left: 5.67px;
  
 
}

.test_data{
font-family: Poppins;
font-size: 13px;
font-weight: 500;



}

.merge_btn{
  background-color: #811113;
  float: right;
  width: 145px !important;
  margin-bottom: 24px;
}



.download_btn{
  background-color: #811113;
  float: right;
  width: 114px !important;
  margin-bottom: 24px;

}

.file_new_text{
font-family: Poppins;
font-size: 16px;
font-weight: 700;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #B7B8BB;

}

.merge_text{
font-family: Poppins;
font-size: 13px;
font-weight: 600;
line-height: 24px;
text-align: left;
color: #4E5157;


}


.file_loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  vertical-align: middle;
  width: 60px;
  height: 60px;
  color: #000000;
}
  .file_loader:before,
  .file_loader:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: 1s spin linear infinite;
  }
  .file_loader:after {
    color: #BF9293;
    transform: rotateY(70deg);
    animation-delay: .4s;
  }

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}

.custom_col {
  flex: 0 0 12%;
  max-width: 12%;
}
.custom_col2{
  flex: 0 0 14%;
  max-width: 14%;
}
.custom_col3{
  flex: 0 0 16%;
  max-width: 16%;
}

.tagpicker_analytics{
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;

}
.shortCode_toggle{
  .rs-toggle-checked .rs-toggle-presentation {
    background-color: #811113 !important;
  }
}


//ellipsis
.truncate-text {
  display: inline-block;
  max-width: 150px; /* Adjust this width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}