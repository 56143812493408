.cus-box{
left: 2px;
border-radius: 10px;
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19);
}

.reportdata_table1 table{
    width: 4200px;
    table-layout: fixed; 
  }
  .reportdata_table1 th{
    //center the text and align it to the top of the td
    vertical-align: top;
    text-align: left;
    border: 1px solid #dee2e6;
    font-size: 14px;
  }
  .reportdata_table1 td{
    text-align: right;
  }

.kpi-table{
    width: auto;
    table-layout: fixed; 
}

th.width-100{
    width: 100px;
  }

  th.width-125{
    width: 125px;
  }

  th.width-150{
    width: 150px;
  }

  th.width-175{
    width: 175px;
  }

  th.width-200{
    width: 200px;
  }

  th.width-225{
    width: 225px;
  }

  th.width-250{
    width: 250px;
  }

  th.width-300{
    width: 300px;
  }
  

td.text-align-left {
  text-align: left !important;
}

td.text-align-right {
  text-align: right !important;
}

@media screen and (max-width: 557px) {
  .col {
    display: block !important;
    justify-content: center !important;
  }
}









