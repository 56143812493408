.input-dtl-leb{
    margin-right: 10px;
    width: 35%;
}

.output-leb{
    color:rgb(43, 56, 231);
    margin-left: 10px;
    
}
.modal-content{
    border:none;
    margin: 10px;
    width:900px fit-content;
}

.modal-midsize-screen{
    margin-top: 5%;
    width:120%;
    height:50%;
}

.customer-input{
    display: block;
    width: 100%;
    padding: 0.42rem 0.70rem;
    margin-top: 10PX;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
.customer-phone-input{
    display: flex;
    width: 100%;
    padding: 0.42rem 0.70rem;
    margin-top: 10PX;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.customer-mult-input{
    margin-top: 10px
}
.lable-customer{
    width:100%;
    margin-top: 10px;
}

.customer-field-container{
    width: 96%;
    margin-left: 2%;
}
.operator-field-container{
    width: 96%;
    // margin-left: 2%;
}
.btn-colection-right{
    flex-direction: row-reverse;
}